var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "d-flex justify-center" }, [
    _c("div", { staticClass: "justify-center flex-column" }, [
      _c("div", { staticClass: "text-center py-2" }, [
        _vm._v(" تیپ غالب شما "),
        _c(
          "span",
          {
            class: `font-weight-bold ${_vm.examResult[0].color.toLowerCase()}--text OutlineText`,
          },
          [_vm._v(_vm._s(_vm.getPersianColorName(_vm.examResult[0].color)))]
        ),
        _vm._v(" است "),
      ]),
      _c("div", { staticClass: "text-center py-2" }, [
        _c("section", { staticClass: "d-flex" }, [
          _c(
            "div",
            {
              staticClass: "p-0",
              style: `width:${_vm.getColumnSize(
                _vm.examResult[0].percentage,
                _vm.examResult[1] != null
                  ? _vm.examResult[0].percentage + _vm.examResult[1].percentage
                  : _vm.examResult[0].percentage
              )}`,
            },
            [
              _c(
                "v-progress-linear",
                {
                  class:
                    _vm.examResult[1] != null
                      ? "border border-end-0 rounded-start-pill"
                      : "border rounded-pill",
                  attrs: {
                    height: "56",
                    value: "100",
                    color: _vm.getProgressColor(_vm.examResult[0].color),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("firstColor")
                    },
                  },
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        class: `f12 OutlineText ${_vm.getFontColor(
                          _vm.examResult[0].color
                        )}--text`,
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getPersianColorName(_vm.examResult[0].color)
                          )
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: `f13 fw-bold OutlineText ${_vm.getFontColor(
                          _vm.examResult[0].color
                        )}--text`,
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.toFarsiNumber(
                              Number(_vm.examResult[0].percentage.toFixed(0))
                            )
                          ) + "%"
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.examResult[1] != null
            ? _c(
                "div",
                {
                  staticClass: "p-0",
                  style: `width:${_vm.getColumnSize(
                    _vm.examResult[1].percentage,
                    _vm.examResult[0].percentage + _vm.examResult[1].percentage
                  )}`,
                },
                [
                  _c(
                    "v-progress-linear",
                    {
                      staticClass: "border border-start-0 rounded-end-pill",
                      attrs: {
                        height: "56",
                        value: "100",
                        color: _vm.getProgressColor(_vm.examResult[1].color),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.scrollMeTo("secondColor")
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            class: `f12 OutlineText ${_vm.getFontColor(
                              _vm.examResult[1].color
                            )}--text`,
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getPersianColorName(_vm.examResult[1].color)
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            class: `f13 fw-bold OutlineText ${_vm.getFontColor(
                              _vm.examResult[1].color
                            )}--text`,
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.toFarsiNumber(
                                  Number(
                                    _vm.examResult[1].percentage.toFixed(0)
                                  )
                                )
                              ) + "%"
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { ref: "firstColor" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.firstColorBody) } }),
      ]),
      _vm.secondColorBody != null
        ? _c("div", { ref: "secondColor" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.secondColorBody) },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }