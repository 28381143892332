<template>
<section class="d-flex justify-center">
  <div class="justify-center flex-column">
    <div class="text-center py-2">
      تیپ غالب شما <span :class="`font-weight-bold ${examResult[0].color.toLowerCase()}--text OutlineText`">{{ getPersianColorName(examResult[0].color) }}</span> است
    </div>
    <div class="text-center py-2">
      <section class="d-flex">
        <div class="p-0" :style="`width:${getColumnSize(examResult[0].percentage, (examResult[1] != null)?(examResult[0].percentage + examResult[1].percentage):(examResult[0].percentage))}`">
          <v-progress-linear :class='(examResult[1] != null)?"border border-end-0 rounded-start-pill":"border rounded-pill"' height="56" value="100" :color="getProgressColor(examResult[0].color)" @click="scrollMeTo('firstColor')">
            <div>
              <div :class="`f12 OutlineText ${getFontColor(examResult[0].color)}--text`">{{ getPersianColorName(examResult[0].color) }}</div>
              <div :class="`f13 fw-bold OutlineText ${getFontColor(examResult[0].color)}--text`">{{ toFarsiNumber(Number((examResult[0].percentage).toFixed(0))) }}%</div>
            </div>
          </v-progress-linear>
        </div>
        <div v-if="examResult[1] != null" class="p-0" :style="`width:${getColumnSize(examResult[1].percentage, examResult[0].percentage + examResult[1].percentage)}`">
          <v-progress-linear class="border border-start-0 rounded-end-pill" height="56" value="100" :color="getProgressColor(examResult[1].color)" @click="scrollMeTo('secondColor')">
            <div>
              <div :class="`f12 OutlineText ${getFontColor(examResult[1].color)}--text`">{{ getPersianColorName(examResult[1].color) }}</div>
              <div :class="`f13 fw-bold OutlineText ${getFontColor(examResult[1].color)}--text`">{{ toFarsiNumber(Number((examResult[1].percentage).toFixed(0))) }}%</div>
            </div>
          </v-progress-linear>
        </div>
      </section>
    </div>
    <div ref="firstColor">
      <span v-html="firstColorBody"></span>
    </div>
    <div ref="secondColor" v-if="secondColorBody != null">
      <span v-html="secondColorBody"></span>
    </div>
  </div>
  <!-- <v-btn fab dark fixed bottom right
    color="primary" v-scroll="onScroll"
    v-show="fab" @click="scrollMeTo('top')">
    <v-icon>mdi-menu-up</v-icon>
  </v-btn> -->
</section>
</template>
<script>
import axios from 'axios';

export default {
  name: 'HartmanResult',
  components: {},
  data() {
    return {
      assignCode: undefined,
      examResult: undefined,
      // data: {
      //   labels: ["سفید", "زرد", "آبی", "قرمز", ],
      //   datasets: [{
      //     borderWidth: 1,
      //     borderColor: [
      //       'GRAY',
      //       'YELLOW',
      //       'BLUE',
      //       'RED'
      //     ],
      //     backgroundColor: [
      //       'rgba(224, 224, 224, 1)',
      //       'rgba(255, 235, 59, 1)',
      //       'rgba(30, 136, 229, 1)',
      //       'rgba(244, 67, 54, 1)',
      //     ],
      //     data: [9, 6, 5, 5]
      //   }]
      // },
      // fab: false,
      firstColorBody: null,
      secondColorBody: null,
    }
  },
  computed: {
    examToDoState: {
      get() {
        return this.$store.getters['exam/getExamToDo']
      },
      set(val) {
        this.$store.commit('exam/updateExamToDo', val)
      }
    },
  },
  beforeMount() {
    this.assignCode = this.$route.params.code
    this.loadExamResult(this.assignCode)
  },
  methods: {
    toFarsiNumber(n) {
      const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

      return n
          .toString()
          .replace(/\d/g, x => farsiDigits[x]);
    },
    loadExamResult(code) {
      this.$axios.get(`exam-result/code/${code}`)
        .then((response) => {
          this.examResult = response.data
          
          this.loadHtmlPage(this.examResult[0].color.toLowerCase())
            .then((response) => {
              this.firstColorBody = response.data
            })
            .catch((error) => {
              console.log(error)
              this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
            })
          
          if(this.examResult.length > 1) {
          this.loadHtmlPage(this.examResult[1].color.toLowerCase())
            .then((response) => {
              this.secondColorBody = response.data
            })
            .catch((error) => {
              console.log(error)
              this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
        })
    },
    getProgressColor(color) {
      switch (color) {
        case 'WHITE':
          return '#EEEEEE'
        case 'YELLOW':
          return '#FDD835'
        case 'RED':
          return '#E53935'
        case 'BLUE':
          return '#20B1EA'
      }
    },
    getPersianColorName(color) {
      switch (color) {
        case 'WHITE':
          return 'سفید'
        case 'YELLOW':
          return 'زرد'
        case 'RED':
          return 'قرمز'
        case 'BLUE':
          return 'آبی'
      }
    },
    getFontColor(color) {
      switch (color) {
        case 'WHITE':
          return 'dark'
        case 'YELLOW':
          return 'dark'
        case 'RED':
          return 'white'
        case 'BLUE':
          return 'white'
      }
    },
    getHtmlUrl(color) {
      return `${color}.html`
    },
    loadHtmlPage(color) {
      return axios.get(`/exams/result/hartman/` + this.getHtmlUrl(color))
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    // onScroll (e) {
    //   if (typeof window === 'undefined') return
    //   const top = window.pageYOffset ||   e.target.scrollTop || 0
    //   this.fab = top > 20
    // },
    // getColumnSize(percentage, sumPercentage) {
    //   var s = Math.round(percentage) * 12 / Math.round(sumPercentage)
    //   if(percentage > sumPercentage / 2) {
    //     return Math.ceil(s)
    //   } else {
    //     return Math.floor(s)
    //   }
    // },
    getColumnSize(percentage, sumPercentage) {
      console.log(percentage+ ' --- ' + sumPercentage);
      var full = percentage * 100 / sumPercentage;
      return Math.abs(full) + '%';
    },
  }
}
</script>
<style lang="scss" scoped>
  .rounded-start-pill {
    border-bottom-right-radius:  9999px!important;
    border-top-right-radius:  9999px!important; 
  }
  .rounded-end-pill {
    border-top-left-radius:  9999px!important;
    border-bottom-left-radius:  9999px!important;
  }

  .OutlineText {
     text-shadow: 0px 0px 3px #00000095;
  }
</style>
