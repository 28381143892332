<template>
  <div class="d-flex justify-center">
    <div class="justify-center d-flex flex-column">
      <div>
          <HartmanResult v-show="examToDoState.code === hartmanCode" />
      </div>
      <div class="d-flex">
        <v-row class="py-5">
          <v-col cols="12" class="pa-2">
            <v-btn block x-large color="light-blue lighten-1 white--text d-none"
              @click="redoExam()">
              تکرار آزمون
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import HartmanResult from '@/components/exam/HartmanResult'

export default {
  name: 'ExamResult',
  components: {
    HartmanResult
  },
  data: () => ({
    examHardCode: [],
    hartmanCode: 504
  }),
  computed: {
    examToDoState: {
      get () {
        return this.$store.getters['exam/getExamToDo']
      },
      set (val) {
        this.$store.commit('exam/updateExamToDo', val)
      }
    }
  },
  mounted() {
    this.updateListExamHardCode()
    this.hartmanCode = this.examHardCode.find(x => x.examName === 'HARTMAN').examCode
  },
  methods: {
    updateListExamHardCode() {
      this.$axios.get('exam-hardcode/all-available?size=100')
      .then(response => {
        this.examHardCode = response.data.content
      })
      .catch( error => {
        console.error(error)
        this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
      })
    },
    redoExam () {
      this.$axios.post('exam/todo/' + this.$route.params.code + '/redo')
      .then(response => {
        this.$axios.get('exam/todo/' + response.data.code)
          .then((response) => {
            this.examToDoState = response.data
          })
          .catch((error) => {
            console.error(error)
            this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
          })
        this.$router.push({ name: 'ExamHome', params: { code: response.data.code } })
      })
      .catch(error => {
        console.error(error)
        this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
