var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex justify-center" }, [
    _c("div", { staticClass: "justify-center d-flex flex-column" }, [
      _c(
        "div",
        [
          _c("HartmanResult", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.examToDoState.code === _vm.hartmanCode,
                expression: "examToDoState.code === hartmanCode",
              },
            ],
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "v-row",
            { staticClass: "py-5" },
            [
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        "x-large": "",
                        color: "light-blue lighten-1 white--text d-none",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.redoExam()
                        },
                      },
                    },
                    [_vm._v(" تکرار آزمون ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }